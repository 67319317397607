import React from "react";

import { Link } from "gatsby";

import SEO from "../components/seo";

const Pricing = () => {
  return (
    <>
      <SEO title="Pricing" />
      <div className="container px-8 pt-24 mx-auto lg:px-4">
        <div className="flex flex-wrap text-center">
          <div className="px-8 py-6 mx-auto lg:px-24 lg:w-2/4 md:w-full">
            <div className="flex flex-col items-center justify-center h-full px-4 py-6 text-center shadow-xl rounded-xl border border-gray-200">
              <h2 className="flex items-baseline justify-center mt-2 text-3xl font-bold leading-none text-black lg:text-6xl">
                $1
                <span className="ml-1 text-base text-gray-600">/mo</span>
              </h2>
              <p className="my-4 text-base leading-relaxed ">
                Own a blog like this for a low monthly cost. Price includes
                hosting and custom website colors.
              </p>
              <p className="my-4 font-light">
                Custom components or functions are not included. Minimum
                contract is 12 months. Cancel and refund anytime.
              </p>
              <a
                href="mailto:unpacky@gmail.com?subject=About%20the%20%241%20blog.&body=Hi."
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center px-8 py-3 mx-auto mt-6 font-semibold text-white transition duration-500 ease-in-out transform bg-black rounded-lg hover:bg-gray-800 hover:to-black focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2"
              >
                Email us
              </a>
            </div>

            <Link
              to="/"
              className="absolute top-3 left-3 flex items-center shadow-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-4 w-4 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg>
              Back to homepage
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
